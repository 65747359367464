import { useState } from 'react';
import './App.css';

function App() {
  const [features, setFeatures] = useState(false);
  return (
    <div className="min-h-screen font-mono bg-gray-900 text-white flex items-center justify-center py-10">
      <div className="max-w-2xl p-8">
        <div className="flex items-center mb-6">
          <div className="w-10 h-10 bg-[#20618E] rounded-md transform rotate-45 mr-4 flex-shrink-0"></div>
        </div>
        <h1 className="text-4xl font-bold font-sans mb-8">Equity</h1>
        <p className="mb-4 text-lg">
          Loyalty programs need a refresh.
        </p>

        {/* <p className="mb-4 text-lg">
          Your customers deserve more than just points.
        </p> */}

        <p className="mb-4 text-lg">
          Equity brings you and your most loyal customers closer together, creating rewarding experiences
          that go beyond the usual points system.
        </p>
        
        <p className="mb-4 text-lg">
          We believe in creating meaningful connections, where your regulars feel like valued partners in your journey.
        </p>
        
        <p className="mb-8 text-lg">
          And, it's is designed to work with your current loyalty program - giving you
          more time to do what you love.
        </p>

        <a className="bg-slate-700 text-white px-6 py-3 rounded-full hover:bg-slate-600 transition-colors" href="https://tally.so/r/mJ0qjo">
          Join Waitlist
        </a>

        
        <p className="mt-10" onClick={() => setFeatures(!features)}>
          <button className="text-white text-left pb-1 border-dashed border-b-2 border-slate-600 hover:border-slate-400 transition-colors">
            p.s. peek at some features we're excited about...
          </button>
        </p>

        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${features ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
          <ul className="list-disc list-inside mb-4 text-lg mt-4">
            <li className="py-1">Enabling regulars to add songs to the café playlist in real-time</li>
            <li className="py-1">Notifying you when a loyal customer is approaching, so their usual order is ready</li>
            <li className="py-1">Letting customers vote on your next seasonal menu item, or in any poll</li>
          </ul>
      </div>
      </div>
    </div>

  );
}

export default App;
